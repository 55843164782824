body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
.ant-typography,
.ant-btn,
.ant-input,
.ant-menu,
.ant-modal-body,
.ant-card-head,
.ant-card-body,
.ant-layout,
.ant-layout-header,
.ant-layout-footer,
.ant-layout-sider,
.ant-layout-content,
.ant-table,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-tabs,
.ant-tabs-tab,
.ant-form,
.ant-form-item-label,
.ant-form-item-control,
.ant-select,
.ant-select-selection-item,
.ant-breadcrumb,
.ant-badge,
.ant-alert,
.ant-dropdown-menu,
.ant-list-item,
.ant-collapse-header,
.ant-tooltip-inner {
  font-family: 'Poppins', sans-serif !important;
}